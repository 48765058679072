

$(document).on('change', 'input.autosubmit[type=checkbox]', function(evt) {
  $(this).closest('form').submit()
  console.log('autosubmit');
});
$(document).on('change', 'input.autosubmit[type=number]', function(evt) {
  $(this).closest('form').submit()
  console.log('autosubmit');
});
$(document).on('change', 'input.autosubmit[type=radio]', function(evt) {
  $(this).closest('form').trigger('submit');
  console.log('autosubmit');
});

$(document).on('change', 'select.autosubmit', function(evt) {
  console.log('autosubmit');
  $(this).closest('form').submit()
});