const { extend } = require("jquery");

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

class addFields {
    // This executes when the function is instantiated.
    constructor() {
        this.links = document.querySelectorAll('.add_fields')
        this.iterateLinks()
    }

    iterateLinks() {
        // If there are no links on the page, stop the function from executing.
        if (this.links.length === 0) return
        // Loop over each link on the page. A page could have multiple nested forms.
        this.links.forEach(link => {
            link.addEventListener('click', e => {
                this.handleClick(link, e)
            })
        })
    }

    handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Save a unique timestamp to ensure the key of the associated array is unique.
        let time = new Date().getTime()
        // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
        let linkId = link.dataset.id
        // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
        let regexp = linkId ? new RegExp(linkId, 'g') : null
        // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
        let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
        // Add the new markup to the form if there are fields to add.
        newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
    }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new addFields())


$(document).on('click', 'a.remove_fields', function (e) {
    let link = $(this);
    if (link.hasClass('remove_fields')) {
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Find the parent wrapper for the set of nested fields.
        let fieldParent = link.closest('.nested-fields')
        // If there is a parent wrapper, find the hidden delete field.
        let deleteField = fieldParent
            ? $(fieldParent).find('input[type="hidden"]')
            : null
        // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
        if (deleteField) {
            $(deleteField).val(1);
            $(fieldParent).hide();
        }
    }
});

$(document).on('click', '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox({
        alwaysShowClose: false,
        loadingMessage: function() {
            console.log('Loading...');
        },
    });
});

$(document).on('click', '[data-behaviour="appstorelink"]', function (event) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // some code..

        var anchor = this;
        event.preventDefault();
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) return;
            window.location = $(anchor).attr('href');
        }, 50);
        window.location = $(this).data('applink');
    }
});

window.turbolinksReload = function () {
    Turbolinks.enableTransitionCache(true);
    Turbolinks.visit(location.toString());
    Turbolinks.enableTransitionCache(false);
}


jscolor.presets.default = {
    // width: 141,               // make the picker a little narrower
    // position: 'right',        // position it to the right of the target
    // previewPosition: 'right', // display color preview on the right
    // previewSize: 40,          // make the color preview bigger
    alpha: 0.7,
    value: '#A57A2C',
    palette: [
        '#000000', '#7d7d7d', '#870014', '#ec1c23', '#ff7e26',
        '#A57A2C', '#22b14b', '#00a1e7', '#3f47cc', '#a349a4',
        '#ffffff', '#c3c3c3', '#b87957', '#feaec9', '#ffc80d',
        '#eee3af', '#b5e61d', '#99d9ea', '#7092be', '#c8bfe7',
    ],
};
$(document).on('turbolinks:load', function () {
    jscolor.install();

    $('input[name="daterange"]').daterangepicker({
        opens: 'left',
        locale: {
            format: 'DD/MM/YYYY'
        }
    }, function (start, end, label) {
        window.location = window.location.pathname + '?daterange=' + start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
    });

    $('.select2').each(function (idx) {
        $(this).width();
    });

    // placeholder example for datatable with checkboxes
    $('.dt').DataTable({
        stateSave: true
    });
    $('.dt-simple').DataTable({
        stateSave: true
    });
    $('.dt-sorted').DataTable({
        order: [[$('.dt-sorted').data('sortordercol'), 'asc']],
        stateSave: false
    });
    $('.dt-sorted-desc').DataTable({
        order: [[$('.dt-sorted-desc').data('sortordercol'), 'desc']],
        stateSave: false
    });
    var sortdir = $('.dt-sorted').data('sortorderdir');
    if (sortdir == undefined) { sortdir = 'asc'; }
    $('.dt-sorted-with-export').DataTable({
        order: [[$('.dt-sorted-with-export').data('sortordercol'), sortdir]],
        stateSave: true,
        dom: 'Bfrtip',
        buttons: [
            {
                extend: 'csv',
                text: 'Export CSV',
                className: 'btn btn-primary',
            },

        ]
    });

    $('#upgradeModal').on('hide.bs.modal', function (e) {
        $("#upgradeModal iframe").attr("src", $("#upgradeModal iframe").attr("src"));
    }) 


    $('select.date-selection:not(.in-cols)').wrap('<div class="col-sm-12 col-md-6">');
    $('select.date-selection').addClass('in-cols');


});

// $(document).on('submit', 'form[method=get][data-remote=false]', function(e) {
//     console.log('SUBMIT!');
//     e.preventDefault();
//     var form = $(this);
//     Turbolinks.visit(form.attr("action") +'?'+ form.serialize());
//   });

window.mapSightingMarker = function (map, sighting, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(sighting.lat), lng: parseFloat(sighting.lon) },
        icon: image,
        map: map,
        title: 'sighting',
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}
window.mapTrapMarker = function (map, trap, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(trap.lat), lng: parseFloat(trap.lon) },
        icon: image,
        map: map,
        title: 'trap',
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}
window.mapHighSeatMarker = function (map, trap, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(trap.lat), lng: parseFloat(trap.lon) },
        icon: image,
        map: map,
        title: 'high seat',
        icon: image,
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}

window.mapEnvironmentDamageMarker = function (map, envd, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(envd.lat), lng: parseFloat(envd.lon) },
        icon: image,
        map: map,
        title: 'Environment Damage',
        icon: image,
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}
window.mapNestMarker = function (map, trap, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(trap.lat), lng: parseFloat(trap.lon) },
        icon: image,
        map: map,
        title: 'nest',
        icon: image,
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}

window.mapRoadTrafficAccidentMarker = function (map, trap, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(trap.lat), lng: parseFloat(trap.lon) },
        icon: image,
        map: map,
        title: 'Road Traffic Accident',
        icon: image,
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}

window.mapCullMarker = function (map, cull, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(cull.fired_from_lat), lng: parseFloat(cull.fired_from_lon) },
        icon: image,
        map: map,
        title: 'cull',
        icon: image,
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}

window.mapRuralCrimeReportingMarker = function (map, rcr, image, infowindow) {
    var marker = new google.maps.Marker({
        position: { lat: parseFloat(rcr.lat), lng: parseFloat(rcr.lon) },
        icon: image,
        map: map,
        title: 'Rural Crime',
        icon: image,
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}

window.mapLabelledMarker = function (map, movement, label, infowindow) {
    var pinColor = "#9E6D2E";
    var pinLabel = label;

    // Pick your pin (hole or no hole)
    var pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
    var labelOriginHole = new google.maps.Point(12, 15);
    var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
    var labelOriginFilled = new google.maps.Point(12, 9);


    var markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: pinSVGFilled,
        anchor: new google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 2,
        strokeColor: "white",
        scale: 2,
        labelOrigin: labelOriginFilled
    };
    var label = {
        text: pinLabel,
        color: "white",
        fontSize: "10px",
    };

    var marker = new google.maps.Marker({
        position: { lat: parseFloat(movement.lat), lng: parseFloat(movement.lon) },
        map: map,
        icon: markerImage,
        label: label,
        zIndex: 1
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}


Dropzone.autoDiscover = false;
$(document).on('turbolinks:load', function () {
    if ($(".misdropzone").length > 0) {
        Dropzone.options.misdropzone = {
            
        };
        new Dropzone(".misdropzone", {
            dictDefaultMessage: 'Drop files here or click to upload',
            acceptedFiles: 'image/jpeg,image/png,application/pdf',
        });
    }
});


$(document).on('turbolinks:load', function () {
    if (window.calendarEventsUrl) {
        var calendarEl = document.getElementById('calendar');
        var calendarDayEl = document.getElementById('day-calendar');
        if (calendarEl) {
            let calendar = (new Calendar(calendarEl, {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                editable: window.editableCalendar,
                aspectRatio: '1',
                initialView: 'dayGridMonth',
                titleFormat: {
                    year: 'numeric',
                    month: 'long'
                },
                headerToolbar: {
                    left: 'prevYear,prev,today',
                    center: 'title',
                    right: 'next,nextYear'
                },
                buttonText: {
                    today: 'Today',
                },
                eventSources: [
                    {
                        url: window.calendarEventsUrl,
                        color: '#9E6D2E',
                        textColor: 'white'
                    }
                ],
                eventTimeFormat: {
                    hour: 'numeric',
                    minute: 'numeric',
                    meridiem: 'short',
                    omitZeroMinute: true
                },
                eventDidMount: function (info) {
                    $(info.el).tooltip({
                        title: info.event.title,
                    });
                },
                dateClick: function (info) {
                    info.jsEvent.preventDefault();
                    if (window.editableCalendar)
                        window.location.replace(window.newEventsUrl + '?date=' + info.dateStr);
                },
                // eventClick: function (info) {
                //     info.jsEvent.preventDefault();
                //     $.get(info.event.url + '.js');
                // },
                // eventDrop: function (info) {
                //     $.ajax({
                //         url: info.event.url + '.js',
                //         type: 'PUT',
                //         data: {
                //             scheduled_report: {
                //                 scheduled_at: info.event.start
                //             }
                //         },
                //     });
                // }
            }));
            calendar.render();
            window.calendar = calendar;
        } else if (calendarDayEl) {
            let calendar = new Calendar(calendarDayEl, {
                plugins: [timeGridPlugin, interactionPlugin],
                editable: true,
                initialView: 'timeGrid',
                // aspectRatio: '0.7',
                height: '700px',
                scrollTime: '09:00:00',
                allDaySlot: false,
                initialDate: window.initialDate,
                headerToolbar: {
                    left: '',
                    center: '',
                    right: ''
                },
                buttonText: {
                    today: 'Today',
                    month: 'Month View',
                    list: 'List View'
                },
                eventSources: [
                    {
                        url: window.calendarEventsUrl,
                        color: '#9E6D2E',
                        textColor: 'white'
                    }
                ],
                eventTimeFormat: {
                    hour: 'numeric',
                    minute: 'numeric',
                    meridiem: 'short',
                    omitZeroMinute: true
                },
                eventShortHeight: 30,
                eventMinHeight: 30,
                slotDuration: '00:15:00',
                //slotMinTime: '06:00:00',
                //slotMaxTime: '19:00:00',
                eventAdd: function (info) {
                    console.log('eventAdd', info);
                },
                eventDidMount: function (info) {
                    $(info.el).tooltip({
                        title: info.event.title,
                    });
                },
                // dateClick: function (info) {
                //     $.get(window.newEventsUrl + '?scheduled_at=' + info.dateStr);
                // },
                eventResize: function (info) {
                    $.ajax({
                        url: info.event.url + '.js',
                        type: 'PUT',
                        data: {
                            shoot_day_drive: {
                                start_time: info.event.start,
                                end_time: info.event.end,
                            }
                        },
                    });
                },
                dateClick: function (info) {
                    info.jsEvent.preventDefault();
                    console.log('dateClick', info);
                    console.log(window.newEventsUrl + '?start_time=' + info.dateStr);
                    window.location.replace(window.newEventsUrl + '?start_time=' + info.dateStr);
                },
                eventClick: function (info) {
                    console.log('eventClick', info);
                    // info.jsEvent.preventDefault();
                    // $.get(info.event.url + '.js');
                },
                eventDrop: function (info) {
                    $.ajax({
                        url: info.event.url + '.js',
                        type: 'PUT',
                        data: {
                            shoot_day_drive: {
                                start_time: info.event.start,
                                end_time: info.event.end,
                            }
                        },
                    });
                }
            });
            calendar.render();
            window.calendar = calendar;
        }
    }
});

$(document).on('turbolinks:load', function () {
  var yesbutton = document.getElementById('yesbutton')
  var nobutton = document.getElementById('nobutton')

  $(yesbutton).on("click", () => {
    $('.licence-needed').show();
    $('.no-licence-needed').hide();
  });
  $(nobutton).on("click", () => {
    $('.no-licence-needed').show();
    $('.licence-needed').hide();
  });

  // Tasks foldable trigger
  $('.form-details-toggler').on('click', function(){
    $(this).closest('.form-details-section').find('.form-details-body').slideToggle(100);
    $(this).find('i').toggleClass('os-icon-ui-22 os-icon-ui-23');
    return false;
  });
});

$(document).on('turbolinks:load', function () {
    proposedBurnsIndexTitle();
    $('.add_fields').on('click', function() {
        setTimeout(proposedBurnsIndexTitle, 50);
        $('.remove_fields').on('click', function() {
            setTimeout(proposedBurnsIndexTitle, 50);
        });
        
    });
    $('.remove_fields').on('click', function() {
        setTimeout(proposedBurnsIndexTitle, 50);
    });
    
});



function proposedBurnsIndexTitle() {
    $('select.date-selection:not(.in-cols)').wrap('<div class="col-sm-12 col-md-6">');
    $('select.date-selection').addClass('in-cols');
    $('.proposed-burns-list .nested-fields:visible .burn-title').each(function(idx) {
        console.log('idx', idx);
        $(this).html('Burn '+ (idx+1));
    })
  }

$.fn.stars = function() {
    return $(this).each(function() {
        const rating = $(this).data("rating");
        const numStars = $(this).data("numStars");
        const fullStar = '<i class="fas fa-star fa-lg"></i>'.repeat(Math.floor(rating));
        const halfStar = (rating%1!== 0) ? '<i class="fas fa-star-half-alt fa-lg"></i>': '';
        const noStar = '<i class="far fa-star fa-lg"></i>'.repeat(Math.floor(numStars-rating));
        $(this).html(`${fullStar}${halfStar}${noStar}`);
    });
}

$(document).on('turbolinks:load', function () {

    $('.stars').stars();

});