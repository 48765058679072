require("bootstrap-daterangepicker/daterangepicker.css");

import 'bootstrap-daterangepicker';

function enableDateFields($scope) {
  function clearDate(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $dateField = $(event.target).closest('.date-input').find('input');

    // clear the cloudinary id.
    $dateField.val(null);

  }

  $scope.find('input.single-daterange').daterangepicker({
    showDropdowns: true,
    minYear: 1980,
    singleDatePicker: true,
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear',
      format: 'DD/MM/YYYY',
    }
  });

  $scope.find('input.single-daterange').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY'));
  });

  $scope.find('input.single-daterange').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $scope.find('input.single-daterange.date-of-birth').daterangepicker({
    showDropdowns: true,
    minYear: 1920,
    maxYear: new Date().getFullYear(),
    startDate: new Date(new Date().getFullYear() - 18, 0, 1),
    singleDatePicker: true,
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear',
      format: 'DD/MM/YYYY',
    }
  });

  $scope.find('input.single-daterange.date-of-birth').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY'));
  });

  $scope.find('input.single-daterange.date-of-birth').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $scope.find(".date-input button.clear").click(clearDate);
}

$(document).on('turbolinks:load', function() {
  enableDateFields($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableDateFields($(event.target));
})
