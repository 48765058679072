



function radioShowHideFields($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  console.log($field.data('showtarget'));
  var field_val = $('input[name="'+$field.attr('name')+'"]:checked').val();
  // console.log(field_val);
  // console.log($field.val());
  $target = $section.find($field.data('showtarget'));

  if ($field.data('showif') && (String(field_val) === String($field.data('showif')))) {
    // console.log('show!');
    // console.log($target);
    $target.show();
  } else {
    // console.log('hide!');
    // console.log($target);
    $target.hide();
  }
}

function radioHideShowFields($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  var field_val = $('input[name="'+$field.attr('name')+'"]:checked').val();
  // console.log($field.data('hidetarget'));
  // console.log(field_val);
  $target = $section.find($field.data('hidetarget'));

  if ($field.data('hideif') && (String(field_val) === String($field.data('hideif')))) {
    // console.log('hide!');
    // console.log($target);
    $target.hide();
  } else {
    // console.log('show!');
    // console.log($target);
    $target.show();
  }
}

function radioHideShowCullGender($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  $target = $section.find('.cull_female_options');

  var field_val = $('input[name="cull[gender]"]:checked').val();
  console.log(field_val);
  if (field_val == 'f') {
    $target.show();
  } else {
    $target.hide();
  }
}

function radioHideShowQuarryClayTypes($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  var field_val = $('input[name="shoot_day[sport_type]"]:checked').val();
  console.log(field_val);
  $('#quarry_type_options').hide();
  $('#clay_type_options').hide();
  if (field_val == 'clay') {
    // $('#quarry_type_options').hide();
    $('#clay_type_options').show();
  } else if (field_val == 'game') {
    $('#quarry_type_options').show();
    // $('#clay_type_options').hide();
  } else if (field_val == 'game_and_clay') {
    $('#quarry_type_options').show();
    $('#clay_type_options').show();
  }
}

function checkboxShowOtherShootDay($field, $other_field) {
  if ($field.is(':checked')) {
    $other_field.show();
  } else {
    $other_field.hide();
  }
}

function checkboxShowOtherPrimaryClassification($field, $other_field) {
  // var classification_field = $('#rural_crime_reporting_primary_classification_id');
  var field_val = $field.find('option:selected').text();
  if ((field_val == 'Other')) {
    $other_field.show();
  } else {
    $other_field.hide();
  }
}

function checkboxShowDateOfBirth($field) {
  if ($field.is(':checked')) {
    $('#date-of-birth-wrapper').show();
  } else {
    $('#date-of-birth-wrapper').hide();
    $('#date-of-birth-wrapper input').val('')
  }
}

$(document).on('change', 'input[data-behaviour~=toggleshow][type=radio]', function(evt) {
  radioShowHideFields($(evt.target));
});
$(document).on('change', 'input[data-behaviour~=togglehide][type=radio]', function(evt) {
  radioHideShowFields($(evt.target));
});


$(document).on('change', 'select#rural_crime_reporting_primary_classification_id', function(evt) {
  checkboxShowOtherPrimaryClassification($('#rural_crime_reporting_primary_classification_id'), $('.other_primary_row'));
});

$(document).on('turbolinks:load', function() {
  $('input[data-behaviour~=toggleshow][type=radio]').each(function(idx) {
    radioShowHideFields($(this));
  });
  $('input[data-behaviour~=togglehide][type=radio]').each(function(idx) {
    radioHideShowFields($(this));
  });
  
  $('input#cull_gender_f,input#cull_gender_m').each(function(idx) {
    radioHideShowCullGender($(this));
  });
  $('input#shoot_day_sport_type,input#shoot_day_sport_type_clay,input#shoot_day_sport_type_game_and_clay').each(function(idx) {
    radioHideShowQuarryClayTypes($(this));
  });
  
  $('input[type="checkbox"].other.shoot-day-quarry-type').each(function(idx) {
    checkboxShowOtherShootDay($(this), $('.other_quarry_row'));
  });

  $('#rural_crime_reporting_primary_classification_id').each(function(idx) {
    checkboxShowOtherPrimaryClassification($(this), $('.other_primary_row'));
  });

  $('input[type="checkbox"].other.shoot-day-clay-type').each(function(idx) {
    checkboxShowOtherShootDay($(this), $('.other_clay_row'));
  });

  $('input[type="checkbox"].under-18-checkbox').each(function(idx) {
    checkboxShowDateOfBirth($(this));
  });
  
})

$(document).on('sprinkles:update', function(event) {
  $('input[data-behaviour~=toggleshow][type=radio]').each(function(idx) {
    radioShowHideFields($(this));
  });
  $('input[data-behaviour~=togglehide][type=radio]').each(function(idx) {
    radioHideShowFields($(this));
  });
  $('input#cull_gender_f,input#cull_gender_m').each(function(idx) {
    radioHideShowCullGender($(this));
  });
  $('input#shoot_day_sport_type_game,input#shoot_day_sport_type_clay,input#shoot_day_sport_type_game_and_clay').each(function(idx) {
    radioHideShowQuarryClayTypes($(this));
  });
})

$(document).on('change', 'input#cull_gender_f,input#cull_gender_m', function(evt) {
  radioHideShowCullGender($(evt.target));
});
$(document).on('change', 'input[type="checkbox"].other.shoot-day-quarry-type', function(evt) {
  checkboxShowOtherShootDay($(this), $('.other_quarry_row'));
});
$(document).on('change', 'input[type="checkbox"].other.shoot-day-clay-type', function(evt) {
  checkboxShowOtherShootDay($(this), $('.other_clay_row'));
});
$(document).on('change', 'input[type="checkbox"].under-18-checkbox', function(evt) {
  checkboxShowDateOfBirth($(this));
});
$(document).on('change', 'input#shoot_day_sport_type_game,input#shoot_day_sport_type_clay,input#shoot_day_sport_type_game_and_clay', function(evt) {
  radioHideShowQuarryClayTypes($(this));
});



$(document).on('change', 'select[data-behaviour~=categoryselector]', function(evt) {
  console.log('changed');
  path = location.pathname + '?category_id=' + $(this).val();
  console.log(path);
  Turbolinks.visit(path)
});


$(document).on('click', 'a[data-behaviour~=selectall]', function(evt) {
  $(this).closest('.selectall-container').find('input[type="checkbox"]').prop('checked', 'checked');
  evt.stopPropagation();
});
$(document).on('click', 'a[data-behaviour~=deselectall]', function(evt) {
  $(this).closest('.selectall-container').find('input[type="checkbox"]').prop('checked', '');
  evt.stopPropagation();
});